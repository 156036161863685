body {
  font-size: 16px;
  margin: 0;
  font-family: 'Helvetica Neue', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lockScroll {
  overflow: hidden;
}

.MuiDataGrid-viewport,
.MuiDataGrid-row,
.MuiDataGrid-renderingZone {
    max-height: fit-content !important;
}

.MuiDataGrid-cell {
    max-height: fit-content !important;
    overflow: auto;
    max-height: inherit;
    white-space: initial !important;
    line-height: 16px !important;
    display: flex !important;
    align-items: center;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

@media only screen and (max-width: 640px) {
  .MuiDataGrid-cell {
 
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  max-height: fit-content !important;
  overflow: auto;
  max-height: inherit;
  white-space: initial !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center;
}
}