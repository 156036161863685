.title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 3px;
}

.subtitle {
  font-size: 1rem;
  opacity: 87%;
}

.carousel .slide img {
  width: 65% !important;
  vertical-align: top;
  border: 0;
}

.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #004C97 !important;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #004C97 !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #004C97 !important;
}

.carousel .control-dots .dot {
  opacity: .3;
  filter: alpha(opacity=30);
  box-shadow: none !important;
  background: #004C97 !important;
}
