.App {
  text-align: left;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  padding: 5px 15px;
  color: white;
}
