.carousel .carousel-status {
  top: -58px;
  right: -19px;
  font-size: 16px;
}

.carousel .control-dots {
  bottom: -37px;
}

.carousel.carousel-slider {
  overflow: visible !important;
}
